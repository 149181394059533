import React, {
  forwardRef
} from 'react'

import AddIcon from '@mui/icons-material/Add';

import styles from './VerificationFilePicker.module.css'
import { t } from 'i18next';

interface IVerificationFilePicker {
  title: string
  docs: File | null
  handleFileChange: (file: File | null) => void
  handleDocsPicker: (ref: HTMLInputElement | null) => void
  isDocsPicked: (el: boolean) => void
}

export const VerificationFilePicker = forwardRef<HTMLInputElement, IVerificationFilePicker>(function CodeInput2FA (props, ref) {
  const { title, handleFileChange, handleDocsPicker, docs, isDocsPicked } = props;

  const onAddFileClick = (): void => {
    if ((ref != null) && 'current' in ref) {
      handleDocsPicker(ref.current);
    }
  };

  const handlerChange = ({ target: { files } }: File | any): void => {
    const file: File = ((files != null) && files.length > 0) ? files[0] : null;
    handleFileChange(file);
    isDocsPicked(true);
  }

  return (
    <div className={styles.filePickerContainer}>
      <label className={styles.filePickerTitle}>{title}</label>
      <input
        type="file"
        onChange={ handlerChange }
        // accept="image/*,.png,.jpeg,.gif,.web"
        className={ styles.hidenDocsPicker }
        ref={ ref }
      />
      <button
        className={ styles.addFileBtn }
        onClick={ onAddFileClick }
      >
        <AddIcon />
        {t('labels.add')}
      </button>
      {docs?.lastModified != null && (
        <p>Selected file: {new Date(docs?.lastModified ?? 0).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
      )}
    </div>
  )
})
