import React, { type FC, type ChangeEvent } from 'react';
import { RadioGroup, FormControlLabel, FormControl, styled } from '@mui/material';

import { RadioButtonComponent } from '../../../../components';
import { type TypesubHeaderOptions } from '../DepositComponent';

import style from './DepositSubHeaderComponent.module.css';
import { t } from 'i18next';

interface InterfaceDepositSubHeaderComponent {
  options: Array<{ ticker: TypesubHeaderOptions }>
  selectedOptions: TypesubHeaderOptions
  setSelectedOptions: (el: TypesubHeaderOptions) => void
}

export const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  paddingBottom: '8px',
  boxSizing: 'border-box'
}))

export const StyledRadioGroup = styled(RadioGroup)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  alignItems: 'center',
  boxSizing: 'border-box',
  gap: '20px',
  height: '70px',
  overflow: 'auto',
  maxWidth: '38vw',

  '@media (max-width: 1239px)': {
    maxWidth: '100vw'
  }
}))

export const DepositSubHeaderComponent: FC<InterfaceDepositSubHeaderComponent> = ({ options, selectedOptions, setSelectedOptions }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedOptions(event.target.value as TypesubHeaderOptions);
  };

  return (
    <div className={ style.subHeader }>
      <h3 className={ style.subHeaderTitle }>{t('labels.deposit_method')}</h3>
        <StyledFormControl>
          <StyledRadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={ selectedOptions }
            onChange={ handleChange }
          >
            {options.map((el) => (
              <FormControlLabel
                sx={{
                  border: `1px solid ${selectedOptions === el.ticker ? 'var(--CFD-theme-System-Tertiary)' : 'var(--CFD-theme-System-OutlineVariant)'}`,
                  borderRadius: '6px',
                  marginLeft: '0',
                  marginRight: '0',
                  paddingRight: '20px',
                  maxWidth: '30%',
                  '&:hover': {
                    backgroundColor: 'var(--CFD-theme-State-layers-on-surface-variant-opacity_08)'
                  }
                }}
                key={el.ticker}
                value={el.ticker}
                control={
                  <RadioButtonComponent
                    checked={selectedOptions === el.ticker}
                    onChange={handleChange}
                    value={el.ticker}
                  />
                }

                label={<div className={ style.assetsCheckContainer }>
                  <p className={ style.assetsCheckContainerTitle }>{t(`deposit_methods.${el.ticker}`)}</p>
                </div>}
              />
            ))}
          </StyledRadioGroup>
        </StyledFormControl>
    </div>
  )
}
