import React, { useState, type FC } from 'react';

import { ModalContext } from '../ModalContext/ModalContext';
import { HeaderModalVerifications } from './Header/HeaderModalVerifications';
import { SectionModalVerifications } from './Section/SectionModalVerifications';
import { FooterModalVerifications } from './Footer/FooterModalVerifications'
import { useBodyOverflow } from '../../hooks/useBodyOverflow';

interface IModalVerifications {
  open: boolean
  setOpen: (el: boolean) => void
}

type TModalVerifications = IModalVerifications

export const ModalVerifications: FC<TModalVerifications> = ({ open, setOpen }) => {
  useBodyOverflow(open);
  const [isFrontSideDocsPicked, setIsFrontSideDocsPicked] = useState<boolean>(false);
  const [isBackSideDocsPicked, setIsBackSideDocsPicked] = useState<boolean>(false);
  const [isFrontSideCardPicked, setIsFrontSideCardPicked] = useState<boolean>(false);
  const [isBackSideCardPicked, setIsBackSideCardPicked] = useState<boolean>(false);
  const [isSelfiePicked, setIsSelfiePicked] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);

  return (
    <ModalContext
      setOpen={setOpen}
      open={open}
      headerComponent={
        <HeaderModalVerifications step={ step } setIsOpen={ setOpen }/>
      }
      sectionComponent={
        <SectionModalVerifications
          step={ step }
          setFrontSideDocsPicked={ setIsFrontSideDocsPicked }
          setBackSideDocsPicked={ setIsBackSideDocsPicked }
          setFrontSideCardPicked={ setIsFrontSideCardPicked }
          setBackSideCardPicked={ setIsBackSideCardPicked }
          setSelfiePicked={ setIsSelfiePicked }
        />
      }
      footerComponent={
        <FooterModalVerifications
          step={ step }
          frontSideDocsPicked={ isFrontSideDocsPicked }
          backSideDocsPicked={ isBackSideDocsPicked }
          frontSideCardPicked={ isFrontSideCardPicked }
          backSideCardPicked={ isBackSideCardPicked }
          selfiePicked={ isSelfiePicked }
          setStep={ setStep }
        />
      }
    />
  )
}
