/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { createContext, useContext, useState, type FC } from 'react';
import { RenderNavigation } from '../structure/RenderNavigation';
import { loginUser, logOutUser } from '../redux/reducers/user';
import { useAppDispatch } from '../hooks/redux';
import { type LoginDataInterface, type PinCodePayloadInterface } from '../interfaces';
import { setIs2FACodeIncorrect, setIs2FAPinCodeModalOpen } from '../redux/reducers/login';

interface AuthInterface {
  token: string | null
  login: (data: LoginDataInterface) => Promise<void>
  loginByToken: (token: string) => void
  logout: (userId: string) => Promise<void>
}

const AuthContext = createContext<AuthInterface>(
  {
    token: '',
    login: async () => {},
    loginByToken: () => {},
    logout: async () => {}
  }
);

export const AuthData = (): AuthInterface => useContext(AuthContext);

export const AuthWrapper: FC = () => {
  const STORAGE_TOKEN = localStorage.getItem('token');
  const [token, setToken] = useState(STORAGE_TOKEN);
  const dispatch = useAppDispatch();

  const login = async (data: LoginDataInterface): Promise<void> => {
    const response = await dispatch(loginUser(data));
    const payload = response.payload as PinCodePayloadInterface;

    if (payload.isError) {
      throw new Error(payload.error as string);
    }

    if (payload?.isNeedPinCode ?? false) {
      dispatch(setIs2FAPinCodeModalOpen(true))
      return;
    }

    if (payload.isPinCodeIncorrect ?? false) {
      dispatch(setIs2FACodeIncorrect(true))
      return;
    }

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!payload.isNeedPinCode || !payload.isPinCodeIncorrect) {
      dispatch(setIs2FAPinCodeModalOpen(false))
    }

    localStorage.setItem('token', payload.data as string);
    setToken(payload.data as string);
  }

  const loginByToken = (token: string): void => {
    localStorage.removeItem('token');
    localStorage.setItem('token', token);
    setToken(token);
  }

  const logout = async (userId: string): Promise<void> => {
    await dispatch(logOutUser(userId));
    localStorage.removeItem('token');
    localStorage.removeItem('uId');
    setToken('');
  }

  return (
      <AuthContext.Provider value={{ token, login, loginByToken, logout }}>
        <RenderNavigation />
      </AuthContext.Provider>
  );
}
