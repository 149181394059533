import AlgorandI from '../../assets/images/Algorand.svg';
import ApeCoinI from '../../assets/images/ape coin.svg';
import AvalancheI from '../../assets/images/Avalanche.svg';
import AxieInf from '../../assets/images/axie infinity.svg';
import BandI from '../../assets/images/Band Protocol.svg';
import BitcoinCashI from '../../assets/images/Bitcoin-Cash.svg';
import BitcoinI from '../../assets/images/Bitcoin.svg';
import CardanoI from '../../assets/images/Cardano.svg';
import ChainlinkI from '../../assets/images/ChainLink.svg';
import DashI from '../../assets/images/Dash.svg';
import DogecoinI from '../../assets/images/Dogecoin.svg';
import EOSI from '../../assets/images/EOS.svg';
import EthereumI from '../../assets/images/Ethereum.svg';
import GalaI from '../../assets/images/gala.svg';
import LitecoinI from '../../assets/images/Litecoin.svg';
import PolkadotI from '../../assets/images/Polkadot.svg';
import PolygonI from '../../assets/images/Polygon.svg';
import RippleI from '../../assets/images/Ripple.svg';
import SolanaI from '../../assets/images/Solana.svg';
import StellarI from '../../assets/images/Stellar.svg';
import TezosI from '../../assets/images/Tezos.svg';
import UniswapI from '../../assets/images/Uniswap.svg';
import CopperI from '../.../../../assets/images/Copper.svg';
import GoldI from '../.../../../assets/images/Gold.svg';
import PalladiumI from '../.../../../assets/images/Palladium.svg';
import PlatinumI from '../.../../../assets/images/Platinum.svg';
import SilverI from '../.../../../assets/images/Silver.svg';
import CocoaI from '../.../../../assets/images/Cocoa.svg';
import CottonI from '../.../../../assets/images/Cotton.svg';
import CoffeeI from '../.../../../assets/images/Coffee.svg'
import NaturalGasI from '../.../../../assets/images/Natural Gas.svg'
import SoyBeanI from '../.../../../assets/images/Soy Bean.svg';
import SugarI from '../.../../../assets/images/Sugar.svg';
import WheatI from '../.../../../assets/images/Wheat.svg';
import UKOilI from '../.../../../assets/images/UKOil.svg';
import AUDCADI from '../.../../../assets/images/AUD-CAD.svg';
import AUDCHFI from '../.../../../assets/images/AUD-CHF.svg';
import AUDJPYI from '../.../../../assets/images/AUD-JPY.svg';
import AUDNZDI from '../.../../../assets/images/AUD-NZD.svg';
import AUDUSDI from '../.../../../assets/images/AUD-USD.svg';
import CADCHFI from '../.../../../assets/images/CAD-CHF.svg';
import CADJPYI from '../.../../../assets/images/CAD-JPY.svg';
import CHFJPYI from '../.../../../assets/images/CHF-JPY.svg';
import EURJPYI from '../.../../../assets/images/EUR-JPY.svg';
import EURAUDI from '../.../../../assets/images/EUR-AUD.svg';
import EURCADI from '../.../../../assets/images/EUR-CAD.svg';
import EURCHFI from '../.../../../assets/images/EUR-CHF.svg';
import EURGBPI from '../.../../../assets/images/EUR-GBP.svg';
import EURNZDI from '../.../../../assets/images/EUR-NZD.svg';
import EURSGDI from '../.../../../assets/images/EUR-SGD.svg';
import EURUSDI from '../.../../../assets/images/EUR-USD.svg';
import GBPAUDI from '../.../../../assets/images/GBP-AUD.svg';
import GBPCADI from '../.../../../assets/images/GBP-CAD.svg';
import GBPCHFI from '../.../../../assets/images/GBP-CHF.svg';
import GBPJPYI from '../.../../../assets/images/GBP-JPY.svg';
import GBPNZDI from '../.../../../assets/images/GBP-NZD.svg';
import GBPUSDI from '../.../../../assets/images/GBP-USD.svg';
import NZDCADI from '../.../../../assets/images/NZD-CAD.svg';
import NZDCHFI from '../.../../../assets/images/NZD-CHF.svg';
import NZDGPYI from '../.../../../assets/images/NZD-GPY.svg';
import NZDJPYI from '../.../../../assets/images/NZD-JPY.svg';
import NZDUSDI from '../.../../../assets/images/NZD-USD.svg';
import USDCADI from '../.../../../assets/images/USD-CAD.svg';
import USDCHFI from '../.../../../assets/images/USD-CHF.svg';
import USDCNYI from '../.../../../assets/images/USD-CNY.svg';
import USDHKDI from '../.../../../assets/images/USD-HKD.svg';
import USDINRI from '../.../../../assets/images/USD-INR.svg';
import USDJPYI from '../.../../../assets/images/USD-JPY.svg';
import USDTRYI from '../.../../../assets/images/USD-TRY.svg';
import USDZARI from '../.../../../assets/images/USD-ZAR.svg';
import USAI from '../.../../../assets/images/USA.svg';
import FranceI from '../.../../../assets/images/France.svg';
import GermanyI from '../.../../../assets/images/Germany.svg';
import JapanI from '../.../../../assets/images/Japan.svg';
import EuropeI from '../.../../../assets/images/Europe (EUR).svg';
import AustraliaI from '../.../../../assets/images/Australia.svg';
import GreatBritainI from '../.../../../assets/images/GreatBritain.svg';
import SpainI from '../.../../../assets/images/Spain.svg';
import AppleI from '../.../../../assets/images/Apple.svg';
import AuroraCannabisI from '../.../../../assets/images/Aurora Cannabis.svg';
import AdobeI from '../../assets/images/Adobe.svg';
import AlbemarleI from '../../assets/images/Albemarle.svg';
import AmgenI from '../../assets/images/Amgen Inc.svg';
import AmazonI from '../../assets/images/Amazon.svg';
import AstrazenecaI from '../../assets/images/Astrazeneca.svg';
import BoeingI from '../../assets/images/Boeing.svg';
import AlibabaI from '../../assets/images/Alibaba.svg';
import BaiduI from '../../assets/images/Baidu.svg';
import BiogenI from '../../assets/images/Biogen Inc.svg';
import BookingI from '../../assets/images/Booking.com.svg';
import BlackRockI from '../../assets/images/BlackRock, Inc..svg';
import BerkshireI from '../../assets/images/Berkshire Hathaway inc.svg';
import CitigroupI from '../../assets/images/Citigroup.svg';
import CaterpillarI from '../../assets/images/Caterpillar.svg';
import CarnivalI from '../../assets/images/Carnival.svg';
import CoinbaseI from '../../assets/images/Coinbase Global, Inc..svg';
import SalesforceI from '../../assets/images/Salesforce.svg';
import CiscoI from '../../assets/images/Cisco.svg';
import CVSI from '../../assets/images/CVS Health Corporation.svg';
import DelataAirlinesI from '../../assets/images/Delta Airlines.svg';
import DropboxI from '../../assets/images/Dropbox.svg';
import WaltDisneyI from '../../assets/images/Disney.svg';
import eBayI from '../../assets/images/eBay.svg';
import EsteeLauderI from '../../assets/images/Estée Lauder.svg';
import FordI from '../../assets/images/Ford 2.svg';
import FedexI from '../../assets/images/FedEx.svg';
import GeneralElectricI from '../../assets/images/Gen. Electric.svg';
import GeneralMotorsI from '../../assets/images/General Motors Company.svg';
import AlphabetI from '../../assets/images/Alphabet.svg';
import GoldmanI from '../../assets/images/Goldman Sachs.svg';
import HomeDepotI from '../../assets/images/Home Depot.svg';
import HPI from '../../assets/images/Hewlett-Packard.svg';
import IBMI from '../../assets/images/IBM.svg';
import IntelCorpI from '../../assets/images/Intel.svg';
import JJI from '../../assets/images/J&J.svg';
import JPMorganI from '../../assets/images/JPMorgan Chase.svg';
import ColaI from '../../assets/images/Coca-Cola.svg';
import LithiumAmericasI from '../../assets/images/Lithium Americas Corp.svg';
import LucidGroupI from '../../assets/images/Lucid Group inc.svg';
import LiAutoI from '../../assets/images/Li Auto Inc.svg';
import LockheedMartinI from '../../assets/images/Lockheed Martin.svg';
import LYFTI from '../../assets/images/Lyft.svg';
import MastercardI from '../../assets/images/MasterCard.svg';
import McDonaldsI from '../../assets/images/McDonalds.svg';
import ThreeMI from '../../assets/images/3M.svg';
import MerkAndCoI from '../../assets/images/Merck & Company, Inc.svg';
import ModernaI from '../../assets/images/Moderna.svg';
import MarvellI from '../../assets/images/Marvell Technology inc.svg';
import MorganStanleyI from '../../assets/images/Morgan Stanley.svg';
import MicronI from '../../assets/images/Micron Technology Inc.svg';
import NetflixI from '../../assets/images/Netflix.svg';
import NioI from '../../assets/images/Nio.svg';
import NikeI from '../../assets/images/Nike.svg';
import NVIDIAI from '../../assets/images/Nvidia.svg';
import OracleI from '../../assets/images/Oracle.svg';
import PepsiCoI from '../../assets/images/PepsiCo.svg';
import PfizerI from '../../assets/images/Pfizer.svg';
import ProcterAndGambleI from '../../assets/images/Procter & Gamble.svg';
import PinterestI from '../../assets/images/Pinterest.svg';
import PayPalI from '../../assets/images/PayPal.svg';
import QualcommI from '../../assets/images/Qualcomm.svg';
import FerrariI from '../../assets/images/Ferrari.svg';
import RioTintoI from '../../assets/images/Rio Tinto Plc.svg';
import SAPI from '../../assets/images/SAP.svg';
import StarbucksI from '../../assets/images/Starbucks.svg';
import ATANDTI from '../../assets/images/AR&T.svg';
import TilrayI from '../../assets/images/Tilray Brands Inc..svg';
import TelosI from '../../assets/images/Telos Corp..svg'
import TravelersI from '../../assets/images/Travelers.svg';
import TeslaI from '../../assets/images/Tesla Inc..svg'
import UberI from '../../assets/images/Uber.svg';
import UnitedHealthI from '../../assets/images/UnitedHealth.svg';
import VisaI from '../../assets/images/Visa.svg';
import ValeI from '../../assets/images/Vale.svg';
import VodafoneGroupI from '../../assets/images/Vodafone.svg';
import VerizonI from '../../assets/images/Verizon.svg';
import WellsFargoI from '../../assets/images/Wells Fargo.svg';
import WalmartI from '../../assets/images/Walmart.svg';
import WynnResortsI from '../../assets/images/Wynn Resorts.svg';
import ExxonMobilI from '../../assets/images/Exxon Mobil.svg';
import ZoomI from '../../assets/images/Zoom.svg';
import SwissTwentyI from '../../assets/images/Swiss 20.svg';
import HangSengI from '../../assets/images/Hang Seng.svg';
import CornI from '../../assets/images/Corn.svg';
import AmericanInternationalI from '../../assets/images/American International Group Inc..svg';
import AllstateI from '../../assets/images/Allstate Corp.svg';
import AdvancedMicroI from '../../assets/images/Advanced Micro Devices, Inc..svg';
import BPI from '../../assets/images/BP PLC..svg';
import MicrosoftI from '../../assets/images/Microsoft Corp..svg';
import SnapI from '../../assets/images/Snap Inc..svg';
import SonyI from '../../assets/images/Sony Group Corp..svg';
import SpotifyI from '../../assets/images/Spotify.svg';
import TevaI from '../../assets/images/Teva Pharmaceutical Industries LTD.svg';
import TripadvisorI from '../../assets/images/Tripadvisor Inc..svg';
import ShibaInuI from '../../assets/images/Shiba Inu.svg';
import AAVEI from '../../assets/images/AAVE.svg';
import BNBI from '../../assets/images/Binance Coin.svg';
import ChilizI from '../../assets/images/Chiliz.svg';
import MultiversXI from '../../assets/images/multiversx.svg';
import ETHCI from '../../assets/images/Ethereum Classic.svg';
import FileCoinI from '../../assets/images/Filecoin.svg';
import CosmosI from '../../assets/images/Cosmos.svg';
import FantomI from '../../assets/images/fantom.svg';
import GraphI from '../../assets/images/The Graph.svg';
import HederaI from '../../assets/images/hedera.svg';
import ICPI from '../../assets/images/Internet Comp.svg';
import IOTAI from '../../assets/images/IOTA.svg';
import KavaI from '../../assets/images/kava.svg';
import DecentralandI from '../../assets/images/Decentraland.svg';
import MakerI from '../../assets/images/Maker.svg';
import NearI from '../../assets/images/NEAR.svg';
import NeoI from '../../assets/images/NEO.svg';
import PepeI from '../../assets/images/pepe.svg';
import QtumI from '../../assets/images/Qtum.svg';
import QuantI from '../../assets/images/Quant.svg';
import StacksI from '../../assets/images/Stacks.svg';
import SynthetixNetworkI from '../../assets/images/Synthetix Network.svg';
import ThorChainI from '../../assets/images/Thorchain.svg';
import SandboxI from '../../assets/images/The sandbox.svg';
import TronI from '../../assets/images/TRON.svg';
import VeChainI from '../../assets/images/VeChain.svg';
import WorldCoinI from '../../assets/images/worldcoin.svg';
import ArkI from '../../assets/images/ARK.svg';
import ArtificialSuperintelligenceAllianceI from '../../assets/images/Artificial Superintelligence Alliance.svg';
import ArweaveI from '../../assets/images/Arweave.svg';
import AxelarI from '../../assets/images/Axelar.svg';
import BonfidaI from '../../assets/images/Bonfida.svg';
import BonkI from '../../assets/images/Bonk.svg';
import BookOfMemeI from '../../assets/images/BOOK OF MEME.svg';
import CLVI from '../../assets/images/CLV.svg';
import ConstitutionDAOI from '../../assets/images/ConstitutionDAO.svg';
import DogwifhatI from '../../assets/images/Dogwifhat.svg';
import FlokiI from '../../assets/images/Floki.svg';
import GolemI from '../../assets/images/Golem.svg';
import HighstreetI from '../../assets/images/Highstreet.svg';
import IotexI from '../../assets/images/Iotex.svg';
import JupiterI from '../../assets/images/Jupiter.svg';
import MinesOfDalarniaI from '../../assets/images/Mines of Dalarnia.svg';
import NotcoinI from '../../assets/images/Notcoin.svg';
import OpenCampusI from '../../assets/images/Open Campus.svg';
import OptimismI from '../../assets/images/Optimism.svg';
import OriginProtocolI from '../../assets/images/Origin Protocol.svg';
import PowerledgerI from '../../assets/images/power ledger.svg';
import REINetworkI from '../../assets/images/REI Network.svg';
import RenI from '../../assets/images/Ren.svg';
import StratisI from '../../assets/images/Stratis.svg';
import WazirXI from '../../assets/images/Wazirx.svg';
import XaiI from '../../assets/images/Xai.svg';
import EtherFiI from '../../assets/images/ether.fi.svg';
import USOilI from '../../assets/images/Icon_commodities_USOil.svg';

export const getIconByName = (name: string): string | undefined => {
  const nameToIcon: Record<string, string | undefined> = {
    USOil: USOilI,
    'Shiba Inu': ShibaInuI,
    Algorand: AlgorandI,
    'Ape Coin': ApeCoinI,
    Avalanche: AvalancheI,
    'Axie Infinity': AxieInf,
    Band: BandI,
    'Bitcoin Cash': BitcoinCashI,
    Bitcoin: BitcoinI,
    Cardano: CardanoI,
    Chainlink: ChainlinkI,
    Dash: DashI,
    Dogecoin: DogecoinI,
    EOS: EOSI,
    Ethereum: EthereumI,
    Gala: GalaI,
    Litecoin: LitecoinI,
    Polkadot: PolkadotI,
    Polygon: PolygonI,
    Ripple: RippleI,
    Solana: SolanaI,
    Stellar: StellarI,
    Tezos: TezosI,
    Uniswap: UniswapI,
    Copper: CopperI,
    Gold: GoldI,
    Palladium: PalladiumI,
    Platinum: PlatinumI,
    Silver: SilverI,
    Cocoa: CocoaI,
    Cotton: CottonI,
    'Natural Gas': NaturalGasI,
    Soybeans: SoyBeanI,
    Sugar: SugarI,
    Wheat: WheatI,
    Coffee: CoffeeI,
    UKOIL: UKOilI,
    'AUD/CAD': AUDCADI,
    'AUD/CHF': AUDCHFI,
    'AUD/JPY': AUDJPYI,
    'AUD/NZD': AUDNZDI,
    'AUD/USD': AUDUSDI,
    'CAD/CHF': CADCHFI,
    'CAD/JPY': CADJPYI,
    'CHF/JPY': CHFJPYI,
    'EUR/JPY': EURJPYI,
    'EUR/AUD': EURAUDI,
    'EUR/CAD': EURCADI,
    'EUR/CHF': EURCHFI,
    'EUR/GBP': EURGBPI,
    'EUR/NZD': EURNZDI,
    'EUR/SGD': EURSGDI,
    'EUR/USD': EURUSDI,
    'GBP/AUD': GBPAUDI,
    'GBP/CAD': GBPCADI,
    'GBP/CHF': GBPCHFI,
    'GBP/JPY': GBPJPYI,
    'GBP/NZD': GBPNZDI,
    'GBP/USD': GBPUSDI,
    'NZD/CAD': NZDCADI,
    'NZD/CHF': NZDCHFI,
    'NZD/GPY': NZDGPYI,
    'NZD/JPY': NZDJPYI,
    'NZD/USD': NZDUSDI,
    'USD/CAD': USDCADI,
    'USD/CHF': USDCHFI,
    'USD/CNY': USDCNYI,
    'USD/HKD': USDHKDI,
    'USD/INR': USDINRI,
    'USD/JPY': USDJPYI,
    'USD/TRY': USDTRYI,
    'USD/ZAR': USDZARI,
    'Dow Jones': USAI,
    VIX: USAI,
    'S&P 500': USAI,
    'USD Index': USAI,
    'CAC 40': FranceI,
    DAX: GermanyI,
    'Nikkei 225': JapanI,
    'Euro Stoxx 50': EuropeI,
    AUS200: AustraliaI,
    FTSE: GreatBritainI,
    IBEX: SpainI,
    'Apple Inc.': AppleI,
    'Aurora Cannabis Inc.': AuroraCannabisI,
    'Adobe Inc.': AdobeI,
    'Albemarle Corp.': AlbemarleI,
    'Amgen, Inc.': AmgenI,
    'Amazon.com, Inc.': AmazonI,
    'AstraZeneca PLC.': AstrazenecaI,
    'Boeing Co.': BoeingI,
    'Alibaba Group Holding Ltd.': AlibabaI,
    'Baidu Inc.': BaiduI,
    'Biogen Inc.': BiogenI,
    'Booking Holdings Inc.': BookingI,
    'BlackRock Inc.': BlackRockI,
    'Berkshire Hathaway Inc.': BerkshireI,
    'Citigroup Inc.': CitigroupI,
    'Caterpillar Inc.': CaterpillarI,
    'Carnival Corp.': CarnivalI,
    'Coinbase Global Inc.': CoinbaseI,
    'Salesforce Inc.': SalesforceI,
    'Cisco Systems Inc.': CiscoI,
    'CVS Health Corp.': CVSI,
    'Delta Air Lines, Inc.': DelataAirlinesI,
    'Dropbox Inc.': DropboxI,
    'Walt Disney Co.': WaltDisneyI,
    'eBay Inc.': eBayI,
    'Estee Lauder Companies Inc.': EsteeLauderI,
    'Ford Motor Co.': FordI,
    'FedEx Corp.': FedexI,
    'General Electric Co.': GeneralElectricI,
    'General Motors Co.': GeneralMotorsI,
    'Alphabet Inc.': AlphabetI,
    'Goldman Sachs Group Inc.': GoldmanI,
    'Home Depot Inc.': HomeDepotI,
    'HP Inc.': HPI,
    'IBM Common Stock': IBMI,
    'Intel Corp.': IntelCorpI,
    'Johnson & Johnson': JJI,
    'JPMorgan Chase & Co.': JPMorganI,
    'Coca-Cola Co.': ColaI,
    'Lithium Americas Corp.': LithiumAmericasI,
    'Lucid Group Inc.': LucidGroupI,
    'Li Auto Inc.': LiAutoI,
    'Lockheed Martin Corp.': LockheedMartinI,
    'LYFT Inc.': LYFTI,
    'Mastercard Inc.': MastercardI,
    "McDonald's Corp.": McDonaldsI,
    '3M Co.': ThreeMI,
    'Merck & Co Inc.': MerkAndCoI,
    'Moderna Inc.': ModernaI,
    'Marvell Technology Inc.': MarvellI,
    'Morgan Stanley': MorganStanleyI,
    'Micron Technology Inc.': MicronI,
    'Netflix Inc.': NetflixI,
    'Nio Inc.': NioI,
    'Nike Inc.': NikeI,
    'NVIDIA Corp.': NVIDIAI,
    'Oracle Corp.': OracleI,
    'PepsiCo, Inc.': PepsiCoI,
    'Pfizer Inc.': PfizerI,
    'Procter & Gamble Co.': ProcterAndGambleI,
    'Pinterest Inc.': PinterestI,
    'PayPal Holdings Inc.': PayPalI,
    'Qualcomm Inc.': QualcommI,
    'Ferrari NV': FerrariI,
    'Rio Tinto PLC': RioTintoI,
    'SAP SE': SAPI,
    'Starbucks Corp.': StarbucksI,
    'AT&T Inc.': ATANDTI,
    'Travelers Companies Inc.': TravelersI,
    'Uber Technologies Inc.': UberI,
    'UnitedHealth Group Inc.': UnitedHealthI,
    'Visa Inc.': VisaI,
    'Vale SA': ValeI,
    'Vodafone Group PLC.': VodafoneGroupI,
    'Verizon Communications Inc.': VerizonI,
    'Wells Fargo & Co.': WellsFargoI,
    'Walmart Inc.': WalmartI,
    'Wynn Resorts LTD.': WynnResortsI,
    'Exxon Mobil Corp.': ExxonMobilI,
    'Zoom Video Communications Inc.': ZoomI,
    'Swiss 20': SwissTwentyI,
    'Hang Seng': HangSengI,
    Corn: CornI,
    'American International Group Inc.': AmericanInternationalI,
    'Allstate Corp.': AllstateI,
    'Advanced Micro Devices, Inc.': AdvancedMicroI,
    'BP PLC.': BPI,
    'Microsoft Corp.': MicrosoftI,
    'Snap Inc.': SnapI,
    'Sony Group Corp.': SonyI,
    'Spotify Technology SA': SpotifyI,
    'Teva Pharmaceutical Industries LTD.': TevaI,
    'Tilray Brands Inc.': TilrayI,
    'Telos Corp.': TelosI,
    'Tripadvisor Inc.': TripadvisorI,
    'Tesla Inc.': TeslaI,
    Aave: AAVEI,
    'Binance Coin': BNBI,
    Chiliz: ChilizI,
    MultiversX: MultiversXI,
    'Ethereum Classic': ETHCI,
    Filecoin: FileCoinI,
    Cosmos: CosmosI,
    Fantom: FantomI,
    'The Graph': GraphI,
    Hedera: HederaI,
    'Internet Comp': ICPI,
    IOTA: IOTAI,
    Kava: KavaI,
    Decentraland: DecentralandI,
    Maker: MakerI,
    NEAR: NearI,
    NEO: NeoI,
    Pepe: PepeI,
    Qtum: QtumI,
    Quant: QuantI,
    Stacks: StacksI,
    'Synthetics Network': SynthetixNetworkI,
    THORChain: ThorChainI,
    'The sandbox': SandboxI,
    Tron: TronI,
    VeChain: VeChainI,
    Worldcoin: WorldCoinI,
    Ark: ArkI,
    'Artificial Superintelligence Alliance': ArtificialSuperintelligenceAllianceI,
    Arweave: ArweaveI,
    Axelar: AxelarI,
    Bonfida: BonfidaI,
    Bonk: BonkI,
    'BOOK OF MEME': BookOfMemeI,
    CLV: CLVI,
    ConstitutionDAO: ConstitutionDAOI,
    Dogwifhat: DogwifhatI,
    FLOKI: FlokiI,
    Golem: GolemI,
    Highstreet: HighstreetI,
    IoTeX: IotexI,
    Jupiter: JupiterI,
    'Mines of Dalarnia': MinesOfDalarniaI,
    Notcoin: NotcoinI,
    'Open Campus': OpenCampusI,
    Optimism: OptimismI,
    'Origin Protocol': OriginProtocolI,
    Powerledger: PowerledgerI,
    'REI Network': REINetworkI,
    Ren: RenI,
    Stratis: StratisI,
    WazirX: WazirXI,
    Xai: XaiI,
    'ether.fi': EtherFiI
  }

  return nameToIcon[name];
}

export enum TradeElementHeight {
  PaddingTop = 32,
  Footer = 65,
  MobileFooter = 70,
  Header = 72,
  Gap = 24,
  OrderAccordionHeader = 65,
  OrderAccordionBody = 162,
  NewsAccordionHeader = 65,
  NewsAccordionBody = 164,
  NewsAccordionPadding = 32,
  MarketHeader = 65,
  MarketSearch = 64,
  MarketMargin = 16,
  ChipsPaddingTopBottom = 48,
  MobilePaddingBetweenNewsMarket = 274,
  MobileOrderAccordionBody = 200,
  TradingModeLabel = 70,
  AlertMessage = 48
}
