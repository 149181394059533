import React, { type FC } from 'react'
import { LocalizationProvider, DatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import { useTheme } from '../ThemeProvider/ThemeProvider';
import styles from './DatePicker.module.css'
import dayjs, { type Dayjs } from 'dayjs';

interface Props {
  value?: string | null
  onChange?: (date: string | null) => void
  label?: string
  labelAvailable?: boolean
  width?: string
  height?: string
}

export const DatePicker: FC<Props> = ({
  value = null,
  onChange,
  label = 'MM/DD/YYYY',
  labelAvailable = true,
  width = '360px',
  height = '10px'
}) => {
  const { theme } = useTheme();

  const handleChange = (value: string | null | Dayjs): void => {
    if (value !== null) {
      const date = dayjs(value).format('YYYY-MM-DD');

      if (onChange !== undefined) onChange(date);
    }
  }

  const handleValue = (value: null | string): Dayjs | null => (value === null ? null : dayjs(value));

  const customTheme = createTheme({
    palette: {
      mode: theme === 'light' ? 'light' : 'dark',
      primary: {
        main: theme === 'light' ? '#4E5B92' : '#B7C4FF'
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            width: '100% !important'
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--CFD-theme-System-Outline)'
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--CFD-theme-System-Outline)'
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--CFD-theme-System-Outline)',
              borderWidth: '1px'
            },
            '& .MuiInputBase-input': {
              color: 'var(--CFD-theme-System-OnSurface)',
              height
            }
          }
        }
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: 'var(--CFD-theme-System-OnSurfaceVariant)'
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'none',
            marginTop: labelAvailable ? '27px' : '0px',
            width: '100%',
            backgroundColor: 'var(--CFD-theme-Surface-4)',
            borderRadius: '4px 4px 0px 0px'
          }
        }
      }
    }
  });

  return (
    <ThemeProvider theme={customTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={styles.datePickerWrapper}>
          <MuiDatePicker
            label={false}
            sx={{ width, height: 'auto' }}
            value={handleValue(value)}
            onChange={handleChange}
          />
            {labelAvailable ? <span>{label}</span> : null}
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  )
}
