import React, { type SyntheticEvent, type FC } from 'react';
import { FormControl, MenuItem, Select, Autocomplete, TextField, Paper } from '@mui/material';
import { type SelectChangeEvent } from '@mui/material/Select';
import { type OptionInterface } from '../../interfaces';
import { getAccountIconBySymbol } from '../../helpers/util';
import { styled } from '@mui/material/styles';

import styles from './SelectComponent.module.css';

interface SelectInterface {
  options: OptionInterface[]
  type?: 'default' | 'search'
  value?: string
  id?: string
  height?: number
  onChange?: (event: SelectChangeEvent<unknown>) => void
  onSearch?: (value: string | OptionInterface | null) => void
}

const CustomizedSelect = styled(Select)(() => ({
  paddingLeft: '16px',
  height: '40px',
  color: 'var(--CFD-theme-System-OnSurfaceVariant)',
  fontFamily: 'Arial',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  textAlign: 'left',
  borderRadius: '4px',
  border: '1px solid var(--CFD-theme-System-Outline)',
  outline: 'unset',
  '& .MuiSelect-select': {
    padding: 0
  },
  '&.Mui-focused': {
    border: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '3px solid var(--CFD-theme-System-Primary)'
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiSvgIcon-root': {
    color: 'var(--CFD-theme-System-OnSurfaceVariant)'
  }
}));

const CustomizedInput = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    paddingLeft: '16px',
    height: '40px',
    borderRadius: '4px',
    border: '1px solid var(--CFD-theme-System-Outline)',
    outline: 'unset',
    '&.Mui-focused': {
      border: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: '3px solid var(--CFD-theme-System-Primary)'
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  '& .MuiSvgIcon-root': {
    color: 'var(--CFD-theme-System-OnSurfaceVariant)'
  },
  '& .MuiIconButton-root': {
    border: '3px solid var(--CFD-theme-System-OnSurfaceVariant)',
    borderRadius: '50%',
    height: '24px',
    width: '24px'
  },
  '& .MuiInputBase-root .MuiInputBase-input': {
    padding: 0,
    color: 'var(--CFD-theme-System-OnSurfaceVariant)',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textAlign: 'left'
  }
}));

const CustomizedMenuItem = styled(MenuItem)(() => ({
  color: 'var(--CFD-theme-System-OnSurface)',
  fontFamily: 'Arial',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  padding: '8px 12px',
  '&:hover': {
    backgroundColor: 'var(--CFD-theme-Surface-5)'
  },
  '&.Mui-selected': {
    backgroundColor: 'var(--CFD-theme-Surface-5)'
  }
}));

const CustomPaper = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  backgroundColor: 'var(--CFD-theme-Surface-3)',
  borderRadius: '4px',
  padding: 0
}));

const MenuProps = {
  PaperProps: {
    style: {
      backgroundColor: 'var(--CFD-theme-Surface-3)',
      borderRadius: '4px',
      padding: 0
    }
  }
};

export const SelectComponent: FC<SelectInterface> = ({
  options = [],
  type = 'default',
  id = '',
  onSearch = () => {},
  value = '',
  height,
  ...rest
}) => {
  const SearchSelect = (): JSX.Element => {
    const handleSearch = (
      event: SyntheticEvent<Element, Event>,
      value: string | OptionInterface | null,
      reason: string
    ): void => {
      onSearch(value);
    }

    return (<Autocomplete
        sx={{ height: `${height}px` }}
        PaperComponent={CustomPaper}
        freeSolo
        options={options}
        onChange={handleSearch}
        value={value}
        id={id}
        renderOption={(props, { label, value, symbol }) => (
            <CustomizedMenuItem
                {...props}
                key={value}
                value={value}
            >
            <div className={styles.currencyContainer}>
              {symbol?.length > 0 && <img src={ getAccountIconBySymbol((symbol as string) ?? '') } className={styles.currencyIcon}/>}
              <p className={styles.currencyTitle}>{(label as string) ?? ''}</p>
            </div>
            </CustomizedMenuItem>
        )}
        renderInput={(params) => <CustomizedInput {...params} />}
    />)
  };

  const DefaultSelect = (): JSX.Element => (
    <CustomizedSelect
        sx={{ height: `${height}px` }}
        MenuProps={MenuProps}
        value={value}
        id={id}
        {...rest}
    >
    {options.map(({ label, value, symbol }) => (
        <CustomizedMenuItem
            key={value}
            value={value}
        >
          <div className={styles.currencyContainer}>
            { symbol?.length > 0 && <img src={ getAccountIconBySymbol((symbol as string) ?? '') } className={styles.currencyIcon}/>}
            <p className={styles.currencyTitle}>{(label as string) ?? ''}</p>
          </div>
        </CustomizedMenuItem>
    ))}
    </CustomizedSelect>
  );

  const setSelectComponent = (): JSX.Element => {
    const typeByElement = {
      default: <DefaultSelect />,
      search: <SearchSelect />
    };

    return typeByElement[type];
  }

  return (
    <FormControl fullWidth>
      { setSelectComponent() }
    </FormControl>
  )
}
