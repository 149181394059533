import React, {
  type HTMLAttributes,
  type FC,
  useState,
  useRef,
  useEffect
} from 'react';
import { SelfieIcon } from '../../../../icons/Selfie';
import { VerificationFilePicker } from '../../../VerificationFilePicker/VerificationFilePicker';
import { BinIcon } from '../../../../icons/BinIcon';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { addVerificationRequest, setSelfieData } from '../../../../../redux/reducers/kycVerification';
import { selectKycSelfieData } from '../../../../../redux/selectors/kycVerification';
import styles from './Step3SectionModalVerifications.module.css';
import { t } from 'i18next';

interface InterfaceStep3SectionModalVerifications {
  setSelfiePicked: (el: boolean) => void
}

type TStep3SectionModalVerifications = InterfaceStep3SectionModalVerifications & HTMLAttributes<HTMLElement>

export const Step3SectionModalVerifications: FC<TStep3SectionModalVerifications> = ({ setSelfiePicked }) => {
  const dispatch = useAppDispatch()

  const [selfie, setSelfie] = useState<File | null>(null);
  const selfieRef = useRef<HTMLInputElement | null>(null);

  const uploadedSelfie = useAppSelector(selectKycSelfieData)

  const formatAddedDate = (date?: string): string => {
    const dateToFormat = date !== undefined ? new Date(date) : new Date();
    return dateToFormat.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  }

  const uploadedSelfieName = uploadedSelfie !== null ? uploadedSelfie.fileUrl : selfie?.name
  const uploadedSelfieDateAdded = uploadedSelfie !== null ? formatAddedDate(uploadedSelfie.updatedAt) : formatAddedDate()

  const uploadFiles = (): void => {
    if (selfie != null) {
      const formData = new FormData();

      formData.append('selfie', selfie, 'selfieFile');

      dispatch(addVerificationRequest(formData));
    }
  };

  useEffect(() => {
    uploadFiles()
  }, [selfie])

  const handleDocsPicker = (inputElement: HTMLInputElement | null): void => {
    if (inputElement !== null) {
      inputElement.click();
    }
  }

  const removeSelfie = (): void => {
    setSelfie(null);
    setSelfiePicked(false);
    dispatch(setSelfieData(null))
  }

  return (
    <section className={ styles.dialogContent }>
      <div className={ styles.dialogContentTopContainer }>
        <SelfieIcon />
        <h3 className={ styles.dialogContentTitle }>
          {t('profile_page.settings.complete_profile.identity_verifications.verification_modal.section.step3.title')}
        </h3>
      </div>
      <div className={ styles.dialogContentTextContainer }>
        <p className={ styles.dialogContentText }>
          {t('profile_page.settings.complete_profile.identity_verifications.verification_modal.section.step3.subtitle')}
        </p>
      </div>
      <div className={ styles.dialogContentBottomContainer }>
        {
          selfie === null && uploadedSelfie === null
            ? <VerificationFilePicker
                ref={ selfieRef }
                title={t('profile_page.settings.complete_profile.identity_verifications.verification_modal.section.step3.upload_title')}
                handleFileChange={ setSelfie }
                handleDocsPicker={ handleDocsPicker }
                docs={ selfie }
                isDocsPicked={ setSelfiePicked }
              />
            : <div className={ styles.dialogUploadFiles }>
                <div className={ styles.dialogUploadFilesInfo }>
                  <p className={ styles.dialogUploadFilesInfoName }>{uploadedSelfieName}</p>
                  <p className={ styles.dialogUploadFilesInfoTime }>
                    {t('labels.added')}: {uploadedSelfieDateAdded}
                  </p>
                </div>
                <button className={ styles.dialogUploadFilesBinBtn } onClick={ removeSelfie }>
                  <BinIcon />
                </button>
              </div>
        }
        <p className={ styles.dialogContentBottomFileSizeText }>{t('labels.file_size')}: 10MB in JPG, JPEG, PNG</p>
      </div>
    </section>
  )
}
