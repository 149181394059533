import React, { type FC, useState, useMemo, type Dispatch, type SetStateAction, useEffect } from 'react';
import { useAppSelector } from '../../../../hooks/redux';
import { SelectComponent, AssetAmountInputComponent } from '../../../../components'
import style from './BetweenAccountsForm.module.css';
import { type OptionInterface } from '../../../../interfaces';
import { type IAccount } from '../../../../redux/reducers/accounts';
import { t } from 'i18next';

interface InterfaceBetweenAcountsForm {
  options: OptionInterface[]
  fromAccount: IAccount | null
  toAccount: IAccount | null
  direction: 'from' | 'to'
  changeOption: Dispatch<SetStateAction<IAccount | null>>
  toAccountValue?: number
  changeToValue?: (value: string) => void
}

export const BetweenAcountsForm: FC<InterfaceBetweenAcountsForm> = (
  {
    options,
    fromAccount,
    toAccount,
    direction,
    changeOption,
    changeToValue = () => {},
    toAccountValue = 0
  }) => {
  const [amountValue, setAmountValue] = useState('');
  const [account, setAccount] = useState<IAccount | null>(null);
  const accounts = useAppSelector((state) => state.accounts);

  const currentOptions: OptionInterface[] = useMemo((): OptionInterface[] => {
    const excludedOption = direction === 'from' ? toAccount : fromAccount;

    return options.filter((option) => option.value !== excludedOption?._id);
  }, [options, fromAccount, toAccount, direction]);

  useEffect(() => {
    setAccount(direction === 'from' ? fromAccount : toAccount);
  }, [fromAccount, toAccount, direction]);

  useEffect(() => {
    if (direction === 'to') {
      setAmountValue(String(toAccountValue))
    }
  }, [toAccountValue, direction]);

  const handleAmountChange = (value: string): void => {
    setAmountValue(value);
    changeToValue(value);
  }

  return (
    <div className={ style.form }>
      <div className={ style.inputWrapper }>
        <label
          htmlFor="account"
          className={ style.label }
        >{t('labels.account')}:</label>
        <SelectComponent
          value={ account?._id ?? '' }
          options={currentOptions}
          onChange={({ target: { value } }) => { changeOption(accounts.find(({ _id }) => _id === value) ?? null); }}
          id="account"
        />
      </div>
      <div className={ style.inputWrapper }>
        <label
          htmlFor={`amount-${account?.cfdAccountCurrency.symbol}`}
          className={ style.label }
        >{t('labels.amount')}:</label>
        <AssetAmountInputComponent
          value={ amountValue }
          setValue={ handleAmountChange }
          currency={account?.cfdAccountCurrency.symbol}
          id={`amount-${account?.cfdAccountCurrency.symbol}`}
          disabled={direction === 'to'}
          placeholder='0.00'
          maxBtn={ false }
        />
      </div>
    </div>
  );
}
