import type { RootState } from '../../redux/store';
import { type VerificationData, type VerifyStatusType } from '../reducers/kycVerification';

export const selectKycUserVerifyStatus = (state: RootState): VerifyStatusType => state.kycVerification.userVerifyStatus;
export const selectIsUserHaveKYC = (state: RootState): boolean => state.kycVerification.isUserHaveKYC;
export const selectKycIsDataLoading = (state: RootState): boolean => state.kycVerification.isDataLoading;

export const selectIdCardFrontData = (state: RootState): VerificationData => state.kycVerification.idCardFrontData;
export const selectIdCardBackData = (state: RootState): VerificationData => state.kycVerification.idCardBackData;
export const selectCreditCardFrontData = (state: RootState): VerificationData => state.kycVerification.creditCardFrontData;
export const selectCreditCardBackData = (state: RootState): VerificationData => state.kycVerification.creditCardBackData;
export const selectKycSelfieData = (state: RootState): VerificationData => state.kycVerification.selfieData;
