import React, { type FC, type ChangeEvent } from 'react';
import { RadioGroup, FormControlLabel, FormControl, styled } from '@mui/material';

import { RadioButtonComponent } from '../../../../components';
import { type TypeSubHeaderWithdrawalsOptions } from '../WithdrawalComponent'

import style from './WithdrawalSubHeaderComponent.module.css';
import { t } from 'i18next';

interface InterfaceWithdrawalSubHeaderComponent {
  options: Array<{ ticker: TypeSubHeaderWithdrawalsOptions }>
  selectedOptions: TypeSubHeaderWithdrawalsOptions
  setSelectedOptions: (el: TypeSubHeaderWithdrawalsOptions) => void
}

export const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  paddingBottom: '8px'
}))

export const StyledRadioGroup = styled(RadioGroup)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  gap: '20px',
  height: '70px',
  overflowX: 'scroll',
  flexWrap: 'nowrap',
  maxWidth: '38vw',

  '@media (max-width: 1239px)': {
    maxWidth: '100vw',
    gap: '16px'
  }
}))

export const WithdrawalSubHeaderComponent: FC<InterfaceWithdrawalSubHeaderComponent> = ({ options, selectedOptions, setSelectedOptions }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedOptions(event.target.value as TypeSubHeaderWithdrawalsOptions);
  };

  return (
    <div className={ style.wrapper }>
      <div className={ style.subHeader }>
        <h3 className={ style.subHeaderTitle }>{t('withdrawal_modal.subheader.title')}</h3>
          <StyledFormControl>
            <StyledRadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={ selectedOptions }
              onChange={ handleChange }
            >
              {options.map((el) => (
                <FormControlLabel
                  sx={{
                    border: `1px solid ${selectedOptions === el.ticker ? 'var(--CFD-theme-System-Tertiary)' : 'var(--CFD-theme-System-OutlineVariant)'}`,
                    borderRadius: '6px',
                    marginLeft: '0',
                    marginRight: '0',
                    minWidth: '220px',
                    '&:hover': {
                      backgroundColor: 'var(--CFD-theme-State-layers-on-surface-variant-opacity_08)'
                    },
                    '@media (max-width: 1239px)': {
                      minWidth: '105px',
                      paddingRight: '12px'
                    },
                    '@media (max-width: 600px)': {
                      minWidth: '85px'
                    }
                  }}
                  key={el.ticker}
                  value={el.ticker}
                  control={
                    <RadioButtonComponent
                      checked={selectedOptions === el.ticker}
                      onChange={handleChange}
                      value={el.ticker}
                    />
                  }

                  label={<div className={ style.assetsCheckContainer }>
                    <p className={ style.assetsCheckContainerTitle }>{ el.ticker }</p>
                  </div>}
                />
              ))}
            </StyledRadioGroup>
          </StyledFormControl>
      </div>
    </div>
  )
}
