import React, {
  type HTMLAttributes,
  type FC,
  useState,
  useRef,
  useEffect
} from 'react';
import { CreditCardIcon } from '../../../../icons/CreditCardIcon';
import { VerificationFilePicker } from '../../../VerificationFilePicker/VerificationFilePicker';
import { BinIcon } from '../../../../icons/BinIcon';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { addVerificationRequest, setCreditCardBackData, setCreditCardFrontData } from '../../../../../redux/reducers/kycVerification';
import { selectCreditCardBackData, selectCreditCardFrontData } from '../../../../../redux/selectors/kycVerification';
import styles from './Step2SectionModalVerifications.module.css';
import { t } from 'i18next';

interface InterfaceStep2SectionModalVerifications {
  setFrontSideCardPicked: (el: boolean) => void
  setBackSideCardPicked: (el: boolean) => void
}

type TStep2SectionModalVerifications = InterfaceStep2SectionModalVerifications & HTMLAttributes<HTMLElement>

export const Step2SectionModalVerifications: FC<TStep2SectionModalVerifications> = ({ setBackSideCardPicked, setFrontSideCardPicked }) => {
  const dispatch = useAppDispatch()

  const [cardFrontSide, setCardFrontSide] = useState<File | null>(null);
  const [cardBackSide, setCardBackSide] = useState<File | null>(null);
  const frontSideCardRef = useRef<HTMLInputElement | null>(null);
  const backSideCardRef = useRef<HTMLInputElement | null>(null);

  const uploadedCreditCardFront = useAppSelector(selectCreditCardFrontData)
  const uploadedCreditCardBack = useAppSelector(selectCreditCardBackData)

  const formatAddedDate = (date?: string): string => {
    const dateToFormat = date !== undefined ? new Date(date) : new Date();
    return dateToFormat.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  }

  const idCardFrontName = uploadedCreditCardFront !== null ? uploadedCreditCardFront.fileUrl : cardFrontSide?.name
  const idCardBackName = uploadedCreditCardBack !== null ? uploadedCreditCardBack.fileUrl : cardBackSide?.name
  const idCardFrontDateAdded = uploadedCreditCardFront !== null ? formatAddedDate(uploadedCreditCardFront.updatedAt) : formatAddedDate()
  const idCardBackDateAdded = uploadedCreditCardBack !== null ? formatAddedDate(uploadedCreditCardBack.updatedAt) : formatAddedDate()

  const uploadFiles = (): void => {
    if ((cardFrontSide != null) && (cardBackSide != null)) {
      const formData = new FormData();

      formData.append('creditCardFront', cardFrontSide, 'creditCardFrontFile');
      formData.append('creditCardBack', cardBackSide, 'creditCardBackFile');

      dispatch(addVerificationRequest(formData));
    }
  };

  useEffect(() => {
    uploadFiles()
  }, [cardFrontSide, cardBackSide])

  const handleDocsPicker = (inputElement: HTMLInputElement | null): void => {
    if (inputElement !== null) {
      inputElement.click();
    }
  }

  const removeFrontSideCard = (): void => {
    setCardFrontSide(null);
    setFrontSideCardPicked(false);
    dispatch(setCreditCardFrontData(null))
    dispatch(setCreditCardBackData(null))
  }

  const removeBackSideCard = (): void => {
    setCardBackSide(null)
    setBackSideCardPicked(false);
    dispatch(setCreditCardFrontData(null))
    dispatch(setCreditCardBackData(null))
  }

  return (
    <section className={ styles.dialogContent }>
      <div className={ styles.dialogContentTopContainer }>
        <CreditCardIcon/>
        <h3 className={ styles.dialogContentTitle }>
          {t('profile_page.settings.complete_profile.identity_verifications.verification_modal.section.step2.title')}
        </h3>
      </div>
      <div className={ styles.dialogContentTextContainer }>
        <p className={ styles.dialogContentText }>
          {t('profile_page.settings.complete_profile.identity_verifications.verification_modal.section.step2.subtitle')}
        </p>
      </div>
      <div className={ styles.dialogContentBottomContainer }>
        {
          cardFrontSide === null && uploadedCreditCardFront === null
            ? <VerificationFilePicker
                ref={ frontSideCardRef }
                title={t('profile_page.settings.complete_profile.identity_verifications.verification_modal.section.step2.upload_titles.front')}
                handleFileChange={ setCardFrontSide }
                handleDocsPicker={ handleDocsPicker }
                docs={ cardFrontSide }
                isDocsPicked={ setFrontSideCardPicked }
              />
            : <div className={ styles.dialogUploadFiles }>
                <div className={ styles.dialogUploadFilesInfo }>
                  <p className={ styles.dialogUploadFilesInfoName }>{idCardFrontName}</p>
                  <p className={ styles.dialogUploadFilesInfoTime }>
                  {t('labels.added')}: {idCardFrontDateAdded}
                  </p>
                </div>
                <button className={ styles.dialogUploadFilesBinBtn } onClick={ removeFrontSideCard }>
                  <BinIcon />
                </button>
              </div>
        }
        {
          cardBackSide === null && uploadedCreditCardBack === null
            ? <VerificationFilePicker
                ref={ backSideCardRef }
                title={t('profile_page.settings.complete_profile.identity_verifications.verification_modal.section.step2.upload_titles.back')}
                handleFileChange={ setCardBackSide }
                handleDocsPicker={ handleDocsPicker }
                docs={ cardBackSide }
                isDocsPicked={ setBackSideCardPicked }
              />
            : <div className={ styles.dialogUploadFiles }>
                <div className={ styles.dialogUploadFilesInfo }>
                  <p className={ styles.dialogUploadFilesInfoName }>{idCardBackName}</p>
                  <p className={ styles.dialogUploadFilesInfoTime }>
                  {t('labels.added')}: {idCardBackDateAdded}
                  </p>
                </div>
                <button className={ styles.dialogUploadFilesBinBtn } onClick={ removeBackSideCard }>
                  <BinIcon />
                </button>
              </div>
        }
        <p className={ styles.dialogContentBottomFileSizeText }>{t('labels.file_size')}: 10MB in JPG, JPEG, PNG</p>
      </div>
    </section>
  )
}
