import React, {
  type HTMLAttributes,
  type FC
} from 'react';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import styles from './HeaderModalVerifications.module.css';
import { t } from 'i18next';

interface IHeaderModalVerifications {
  step: number
  setIsOpen: (el: boolean) => void
}

type THeaderModalVerifications = IHeaderModalVerifications & HTMLAttributes<HTMLElement>

export const HeaderModalVerifications: FC<THeaderModalVerifications> = ({ step, setIsOpen }) => {
  const handleBtnClick = (): void => {
    setIsOpen(false)
  }

  return (
    <header className={ styles.dialogHeader }>
      <div className={ styles.dialogHeaderContainer }>
        <div className={ styles.dialogHeaderContent }>
          <h3 className={ styles.dialogHeaderTitle }>{t('profile_page.settings.complete_profile.identity_verifications.verification_modal.header.title')}</h3>
          {
            step !== 4
              ? <p className={ styles.dialogHeaderText }>{t('labels.step')} {step}/<span className={ styles.dialogHeaderStep2 }>3</span></p>
              : <p className={ styles.dialogHeaderText }>{t('labels.success')}</p>
          }
        </div>
        <div className={ styles.dialogHeaderBtnContainer }>
         <CloseOutlinedIcon onClick={ handleBtnClick } sx={{ color: 'var(--CFD-theme-System-OnSurface)', cursor: 'pointer' }}/>
        </div>
      </div>
    </header>
  )
}
