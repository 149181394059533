import React, { type FC, useState, type ReactElement, useEffect } from 'react';
import { t } from 'i18next';

import { WithdrawalHeaderComponent } from './WithdrawalHeaderComponent/WithdrawalHeaderComponent';
import { WithdrawalSubHeaderComponent } from './WithdrawalSubHeaderComponent/WithdrawalSubHeaderComponent';
import { CardWithdrawSection } from './CardWithdrawalSection/CardWithdrawalSection';
import { AddWithdrawalMethodSection } from './AddWithdrawalMethodSection/AddWithdrawalMethodSection';
import { AddCardFormComponent } from './AddCardFormComponent/AddCardFormComponent';
import { BankWithdrawSection } from './BankWithdrawalSection/BankWithdrawalSection';
import { AddBankFormComponent } from './AddBankFormComponent/AddBankFormComponent';
import { CryptoWithdrawSection } from './CryptoWithdrawalSection/CryptoWithdrawalSection';
import { AddCryptoFormComponent } from './AddCryptoFormComponent/AddCryptoFormComponent';
import { type IAccount } from '../../../redux/reducers/accounts';
import { setShowFooter } from '../../../redux/reducers/showFooter';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';

import style from './WithdrawalComponent.module.css';

interface InterfaceWithdrawalComponent {
  setOpen: (el: boolean) => void
  selectedAccount: IAccount | null
}

export type TypeSubHeaderWithdrawalsOptions = 'Crypto' | 'Card' | 'Bank'

const options: Array<{ ticker: TypeSubHeaderWithdrawalsOptions }> = [
  { ticker: 'Card' },
  { ticker: 'Bank' },
  { ticker: 'Crypto' }
];

const WithdrawalNodes = {
  Card: CardWithdrawSection,
  Bank: BankWithdrawSection,
  Crypto: CryptoWithdrawSection
};
const WithdrawalAddMethodsNodes = {
  Card: AddCardFormComponent,
  Bank: AddBankFormComponent,
  Crypto: AddCryptoFormComponent
}

export const WithdrawalComponent: FC<InterfaceWithdrawalComponent> = ({ setOpen, selectedAccount }) => {
  const dispatch = useAppDispatch();
  const [selectedAsset, setSelectedAsset] = useState<TypeSubHeaderWithdrawalsOptions>(options[0].ticker);
  const [step, setStep] = useState<number>(1);
  const [addWithdrawalMethod, setAddWithdrawalMethod] = useState(false);
  const [screenWidth] = useWindowSize();
  const { showFooter } = useAppSelector((state) => state.showFooter);

  const isMobile = screenWidth <= 599;

  useEffect(() => {
    if (isMobile && showFooter) dispatch(setShowFooter(false));
  }, []);

  const renderSelectedWithdrawalForm = (): ReactElement => {
    const Component = WithdrawalNodes[selectedAsset];

    const props = { setOpen, step, setStep, setAddWithdrawalMethod, selectedAccount };

    return <Component {...props} />;
  };

  const renderSelectedFormComponent = (): ReactElement => {
    const Component = WithdrawalAddMethodsNodes[selectedAsset];

    const props = { setAddWithdrawalMethod };

    return <Component {...props} />;
  }

  return (
    <div className={ style.mainWrapper }>
      { addWithdrawalMethod
        ? <AddWithdrawalMethodSection setOpen={ setOpen } setAddWithdrawalMethod={ setAddWithdrawalMethod } selectedAccount={selectedAccount}>
            { renderSelectedFormComponent() }
          </AddWithdrawalMethodSection >
        : <>
        <WithdrawalHeaderComponent setOpen={ setOpen } title={`${t('labels.withdrawal')} ${selectedAccount?.cfdAccountCurrency.symbol ?? ''}`}/>
        <div className={ style.bodywrapper } >
          { step === 1
            ? <WithdrawalSubHeaderComponent options={ options } selectedOptions={ selectedAsset } setSelectedOptions={ setSelectedAsset }/>
            : null
          }
          { renderSelectedWithdrawalForm() }
        </div>
        </>}
    </div>
  )
}
