import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type LoginDataInterface, type PayloadInterface } from '../../interfaces';
import { apiHelper } from '../api/apiHelper';
import { type PinCodePayloadInterface } from '../../interfaces/PinCodePayloadInterface';
import { type ErrorResponseInterface } from '../../interfaces/ErrorInterface';

interface IBrand {
  _id: string
  landingUrl: string
}

interface UserInterface {
  token?: string
  _id?: string
  email?: string
  username?: string
  password?: string
  firstName?: string
  lastName?: string
  salesStatusId?: string
  clientType?: number
  clientStatus?: number
  countryCode?: string
  phone?: string
  fullAddress?: string
  dateOfBirth?: string
  additionalInfo?: string
  mnemonic?: string
  roleId?: string
  assignedTo?: string
  isVerified?: boolean
  verifiedAt?: string
  status?: boolean
  isDeleted?: boolean
  otpCode?: string
  otpStatus?: boolean
  otpVerifiedAt?: string
  privateKey?: string
  isLogin?: boolean
  affialiateToken?: string
  brand?: IBrand
  brandId?: string
  isChatEnabled?: boolean
  secret?: string
  qrCode?: string
  createdAt?: string
  updatedAt?: string
  profileImage?: string
  customId?: number
  lastLoginAt?: string
  autoTraderId?: string
  __v?: number
}

const initialState: UserInterface = {};

export const getUserById = createAsyncThunk(
  'user/get',
  async (id: string) => {
    const response = await apiHelper({ method: 'get', path: `/user/${id}` });

    return response.data.user[0];
  }
);

export const getUserByToken = createAsyncThunk(
  'user/getByToken',
  async () => {
    const response = await apiHelper({ method: 'get', path: '/user/by-token' });
    localStorage.setItem('uId', response.data.user._id as string ?? '')

    return response.data.user;
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser (state, action: PayloadAction<UserInterface>) {
      return action.payload;
    },
    setAutotraderId (state, action: PayloadAction<string>) {
      return { ...state, autoTraderId: action.payload }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserById.fulfilled, (state, action: PayloadAction<UserInterface>) => {
      return action.payload;
    });
    builder.addCase(getUserByToken.fulfilled, (state, action: PayloadAction<UserInterface>) => {
      return action.payload;
    });
  }
});

export const { setUser, setAutotraderId } = userSlice.actions;

export const verifyAccount = createAsyncThunk('user/verify', async (token: string, { dispatch, rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await apiHelper({ method: 'get', path: `/auth/verify/${token}` });

    dispatch(setUser(response.data.user as UserInterface));
    localStorage.setItem('uId', response.data.user._id as string);

    const successPayload: PayloadInterface = {
      data: response.data.token,
      isError: false
    }

    return fulfillWithValue(successPayload);
  } catch (error: any) {
    const { data } = error.response as ErrorResponseInterface;
    const isValidationError = Object.hasOwnProperty.call(data, 'error');
    const message = !isValidationError ? data.message : data.error;

    const errorPayload: PayloadInterface = {
      error: message,
      isError: true
    };

    if (!data.success && data.redirectUrl) {
      window.open(data.redirectUrl, '_self');
    }

    return rejectWithValue(errorPayload);
  }
});

export const resendToken = createAsyncThunk('user/resend', async (data: object, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await apiHelper({ method: 'post', path: '/auth/resend', data });

    const successPayload: PayloadInterface = {
      data: response.data.message,
      isError: false
    }

    return fulfillWithValue(successPayload);
  } catch (error: any) {
    const { data } = error.response;
    const isValidationError = Object.hasOwnProperty.call(data, 'error');
    const message = !isValidationError ? data.message : data.error;

    const errorPayload: PayloadInterface = {
      error: message,
      isError: true
    };

    return rejectWithValue(errorPayload);
  }
});

export const registerUser = createAsyncThunk('user/register', async (data: object, { dispatch, rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await apiHelper({ method: 'post', path: '/auth/register', data });

    dispatch(setUser(response.data.user as UserInterface));

    const successPayload: PayloadInterface = {
      data: response.data.message,
      isError: false
    }

    return fulfillWithValue(successPayload);
  } catch (error: any) {
    const { data } = error.response;
    const isValidationError = Object.hasOwnProperty.call(data, 'error');
    const message = !isValidationError ? data.message : data.error;

    const errorPayload: PayloadInterface = {
      error: message,
      isError: true
    };

    return rejectWithValue(errorPayload);
  }
})

export const recoverPassword = createAsyncThunk('user/passwordRecover', async (recoveryData: any, { dispatch, rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await apiHelper({ method: 'post', path: `/auth/reset/${recoveryData.token}`, data: recoveryData.data });

    dispatch(setUser(response.data.user as UserInterface));
    localStorage.setItem('uId', response.data.user._id as string);

    const successPayload: PayloadInterface = {
      data: response.data.token,
      isError: false
    }

    return fulfillWithValue(successPayload);
  } catch (error: any) {
    const { data } = error.response;
    const isValidationError = Object.hasOwnProperty.call(data, 'error');
    const message = !isValidationError ? data.message : data.error;

    const errorPayload: PayloadInterface = {
      error: message,
      isError: true
    };

    return rejectWithValue(errorPayload);
  }
});

export const sendPasswordReset = createAsyncThunk('user/passwordReset', async (data: object, { rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await apiHelper({ method: 'post', path: '/auth/recover', data });

    const successPayload: PayloadInterface = {
      data: response.data.message,
      isError: false
    }

    return fulfillWithValue(successPayload);
  } catch (error: any) {
    const { data } = error.response;
    const message = data.error?.email ?? data.message;

    const errorPayload: PayloadInterface = {
      error: message,
      isError: true
    };

    return rejectWithValue(errorPayload);
  }
});

export const logOutUser = createAsyncThunk(
  'user/logout',
  async (userId: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await apiHelper({ method: 'post', path: `/auth/update-logout-activity/${userId}` });

      const successPayload: PayloadInterface = {
        data: response.data.message,
        isError: false
      }

      return fulfillWithValue(successPayload);
    } catch (error: any) {
      const { data } = error.response;
      const message = data.error?.email ?? data.message;

      const errorPayload: PayloadInterface = {
        error: message,
        isError: true
      };

      return rejectWithValue(errorPayload);
    }
  }
);

export const loginUser = createAsyncThunk('user/login', async (loginData: LoginDataInterface, { dispatch, rejectWithValue, fulfillWithValue }) => {
  try {
    const response = await apiHelper({ method: 'post', path: '/auth/login', data: loginData });

    if (response.data.success === false && response.data?.needPinCode === true) {
      const result: PinCodePayloadInterface = {
        isError: false,
        isNeedPinCode: true
      }
      return result
    }

    dispatch(setUser(response.data.user[0].users as UserInterface));
    localStorage.setItem('uId', response.data.user[0].users._id as string);

    const successPayload: PayloadInterface = {
      data: response.data.token,
      isError: false
    }

    return fulfillWithValue(successPayload);
  } catch (error: any) {
    const { data } = error.response;

    if (data.success === false && data?.needPinCode === true) {
      const result: PinCodePayloadInterface = {
        isError: false,
        isPinCodeIncorrect: true
      }
      return result
    }

    const message = data.error?.email ?? data.message;

    const errorPayload: PayloadInterface = {
      error: message,
      isError: true
    };

    return rejectWithValue(errorPayload);
  }
});

export default userSlice.reducer;
