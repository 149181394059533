import React, { useEffect, type FC } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { ButtonComponent, useSnackbar } from '../../../../components';
import { VerificationContent } from '../VerificationContent/VerificationContent';
import { TwoFAContent } from '../2FAContent/TwoFAContent';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { selectProfileSettingsIsCreditCardVerified, selectProfileSettingsIsIdCardVerified, selectProfileSettingsIsSelfieVerified } from '../../../../redux/selectors/profileSettings';
import { deleteVerificationRequest, fetchUserVerificationData } from '../../../../redux/reducers/kycVerification';
import { selectIsUserHaveKYC } from '../../../../redux/selectors/kycVerification';
import { fetchUser2FAData } from '../../../../redux/reducers/twoFactorAuthentication';
import { selectIsUserDataAvailable } from '../../../../redux/selectors/user';
import { t } from 'i18next';
import style from './CompleteProfileSettings.module.css';

interface InterfaceCompleteProfileSettingsProps {
  setOpenVerificationModal: (el: boolean) => void
  setOpen2FAModal: (el: boolean) => void
}

export const CompleteProfileSettings: FC<InterfaceCompleteProfileSettingsProps> = ({ setOpen2FAModal, setOpenVerificationModal }) => {
  const dispatch = useAppDispatch()
  const { handleOpen } = useSnackbar()

  const isUserDataAvailable = useAppSelector(selectIsUserDataAvailable)
  const VerificationComplete = useAppSelector(selectIsUserHaveKYC);

  const isIdCardVerified = useAppSelector(selectProfileSettingsIsIdCardVerified)
  const isCreditCardVerified = useAppSelector(selectProfileSettingsIsCreditCardVerified)
  const isSelfieVerified = useAppSelector(selectProfileSettingsIsSelfieVerified)

  const isVerifyStarted = !isIdCardVerified && !isCreditCardVerified && !isSelfieVerified

  const handleKycEditIcon = (): void => {
    setOpenVerificationModal(true)
  }

  const handle2FAEditIcon = (): void => {
    console.log('handle2FAEditIcon: Edit Icon Click')
  }

  const handleKycBinIcon = (): void => {
    dispatch(deleteVerificationRequest(handleOpen))
  }

  const handle2FABinIcon = (): void => {
    console.log('handle2FABinIcon: Bin Icon Click')
  }

  useEffect(() => {
    if (isUserDataAvailable) {
      dispatch(fetchUserVerificationData())
      dispatch(fetchUser2FAData())
    }
  }, [isUserDataAvailable])

  return (
    <>
        <div className={ style.settingsFirstSectionContentContainer }>
            <div className={ style.settingsFirstSectionContent }>
                <h4 className={ style.settingsFirstSectionContentTitle }>{t('profile_page.settings.complete_profile.titles.identity_verifications')}</h4>
                {/* <TooltipSmallComponent content='' disableFocusListener disableTouchListener placement='left' arrow={ false }> */}
                    <InfoOutlinedIcon sx={{ color: 'var(--CFD-theme-System-Error)', marginRight: '14px' }} />
                {/* </TooltipSmallComponent> */}
            </div>
                <p className={ style.settingsFirstSectionContentText }>
                    {t('profile_page.settings.complete_profile.identity_verifications.subtitle')}
                </p>
                <div className={ style.settingsStatusContainer }>
                    <div className={ `${style.statusText} ${isIdCardVerified ? style.successDocs : style.unSuccessDocs}` }>
                        {
                            isIdCardVerified
                              ? <DoneOutlinedIcon fontSize='small' />
                              : <RemoveOutlinedIcon fontSize='small' />
                        }
                        {t('profile_page.settings.complete_profile.identity_verifications.statuses.idCard')}
                    </div>
                    <div className={ `${style.statusText} ${isCreditCardVerified ? style.successDocs : style.unSuccessDocs}` }>
                        {
                            isCreditCardVerified
                              ? <DoneOutlinedIcon fontSize='small' />
                              : <RemoveOutlinedIcon fontSize='small' />
                        }
                        {t('profile_page.settings.complete_profile.identity_verifications.statuses.creditCard')}
                    </div>
                    <div className={ `${style.statusText} ${isSelfieVerified ? style.successDocs : style.unSuccessDocs}`}>
                        {
                            isSelfieVerified
                              ? <DoneOutlinedIcon fontSize='small' />
                              : <RemoveOutlinedIcon fontSize='small' />
                        }
                        {t('profile_page.settings.complete_profile.identity_verifications.statuses.selfie')}
                    </div>
                </div>
            <div className={ style.settingsFirstSectionContentBtnContainer }>
                <div className={ style.btnContainer}>
                    {
                        isVerifyStarted && (
                            <ButtonComponent
                                variant='text'
                                onClick={ () => { setOpenVerificationModal(true); } }
                            >
                                {t('profile_page.settings.complete_profile.identity_verifications.verify_button_text')}
                            </ButtonComponent>
                        )
                    }
                </div>
            </div>
            {
                VerificationComplete && <VerificationContent
                    handleBinIcon={handleKycBinIcon}
                    handleEditIcon={handleKycEditIcon}
                />
            }
        </div>
        <div className={ style.settingsSecondSectionContentContainer }>
            <div className={ style.settingsFirstSectionContent }>
                <h4 className={ style.settingsFirstSectionContentTitle }>{t('profile_page.settings.complete_profile.titles.google_2fa')}</h4>
                {/* <TooltipSmallComponent content='Support Text' disableFocusListener disableTouchListener placement='left' arrow={ false }> */}
                    <InfoOutlinedIcon sx={{ color: 'var(--CFD-theme-System-Error)', marginRight: '14px' }} />
                {/* </TooltipSmallComponent> */}
            </div>
            <p className={ style.settingsSecondSectionContentText }>
                {t('profile_page.settings.complete_profile.google_2fa.subtitle')}
            </p>
            <div className={ style.settingsSecondSectionContentBtnContainer }>
                <div className={ style.btnSecondContainer}>
                    <ButtonComponent variant='text' onClick={ () => { setOpen2FAModal(true); } }>
                        {t('profile_page.settings.complete_profile.google_2fa.2fa_button_text')}
                    </ButtonComponent>
                </div>
            </div>
            <TwoFAContent
                handleBinIcon={handle2FABinIcon}
                handleEditIcon={handle2FAEditIcon}
            />
        </div>
    </>
  )
}
