import React, { useEffect, useState, useMemo, type FC } from 'react'
import { FabComponent } from '../FabComponent/FabComponent'
import { AddFundsIcon } from '../icons/AddFundsIcon'
import { useTheme } from '../ThemeProvider/ThemeProvider';
import { TransactionModalContext, MenuSettingsComponent, useSnackbar, type PositionType } from '../../components';
import { DepositComponent } from '../../pages/Accounts/DepositSection/DepositComponent'
import { getAccounts as getAllAccounts } from '../../redux/reducers/accounts';
import { getTotalBalance, getTotalCredit } from '../../redux/reducers/cfd';
import { getHeaderCurrencyRates } from '../../redux/reducers/currencyRates';
import style from './Header.module.css'
import { useAppSelector, useAppDispatch } from '../../hooks/redux'
import { toFixed, getCurrencySymbol } from '../../helpers/util';
import { selectUserProfileImage } from '../../redux/selectors/user';
import { Avatar } from '@mui/material';
import { t } from 'i18next';
import { socket } from '../../web/socket'
import { AuthData } from '../../auth/AuthWrapper';
import { type SetStateAction } from '../PasswordInputComponent/PasswordInputComponent';
import { LogoIcon } from '../icons/LogoIcon';
import { useLocation } from 'react-router-dom';
import { useBodyOverflow } from '../../hooks/useBodyOverflow';

interface InterfaceHeader {
  openMenu: boolean
  setOpenMenu: (el: SetStateAction<boolean>) => void
}

export const Header: FC<InterfaceHeader> = ({ openMenu, setOpenMenu }) => {
  const dispatch = useAppDispatch();
  const { handleOpen } = useSnackbar();
  const { logout } = AuthData();
  const [openDeposit, setOpenDeposit] = useState<boolean>(false);
  const { theme } = useTheme();
  const { _id: userId, firstName, lastName, brand } = useAppSelector((state) => state.user);
  const accounts = useAppSelector((state) => state.accounts);
  const profileImage: string | null = useAppSelector(selectUserProfileImage);
  const isAlertMessage = useAppSelector((state) => state.alertMessage.isAlertMessage);
  const location = useLocation();
  const isAlertMessageShown = (isAlertMessage && location.pathname.includes('/trade'));
  useBodyOverflow(openDeposit);

  const imageLink = `${process.env.REACT_APP_IMAGES_URL}${profileImage}`

  const selectedAccount = useMemo(() => (
    accounts.find((account) => account.isActive) ?? null
  ), [accounts]);

  const symbolIcon = useMemo(() => (getCurrencySymbol(selectedAccount?.cfdAccountCurrency.symbol ?? '')), [selectedAccount])

  useEffect(() => {
    dispatch(getHeaderCurrencyRates());
  }, []);

  useEffect(() => {
    if (userId !== undefined) {
      socket.emit('startStreaming', { userId });
      socket.on(`onLogoutUser&${userId}`, () => { logout(userId); });
      socket.on(`reciveTransactionNotification&${userId}`, ({ type, message }) => {
        const openParams = {
          message,
          header: `The ${type} success!`,
          modalType: 'transaction',
          position: { vertical: 'bottom', horizontal: 'left' } satisfies PositionType,
          actionText: '',
          autoClose: false
        }

        handleOpen(openParams);

        Promise.allSettled([
          dispatch(getAllAccounts(userId)),
          dispatch(getTotalBalance(userId)),
          dispatch(getTotalCredit(userId))
        ])
      });

      Promise.allSettled([
        dispatch(getAllAccounts(userId)),
        dispatch(getTotalBalance(userId)),
        dispatch(getTotalCredit(userId))
      ])
    }

    return () => {
      if (userId !== undefined) {
        socket.off(`reciveTransactionNotification&${userId}`);
        socket.off(`onLogoutUser&${userId}`)
      }
    }
  }, [userId])

  const depositFundsBtn = (): void => {
    setOpenDeposit(true);
  }

  const settingsBtn = (): void => {
    setOpenMenu((prev) => !prev);
  }

  const TransactionModal = useMemo(() => {
    return (
      <TransactionModalContext
        open={ openDeposit }
        setOpen={ setOpenDeposit }
        component={
          <DepositComponent setOpen={ setOpenDeposit } selectedAccount={ selectedAccount } />
        }
      />
    )
  }, [openDeposit, selectedAccount]);

  const MenuSetting = useMemo(() => {
    return openMenu && <MenuSettingsComponent setOpenMenu={ setOpenMenu } />
  }, [openMenu])

  return (
    <>
      <div
        className={`${style.wrapper} ${style[`wrapper-${theme}`]}`}
        style={{
          height: isAlertMessageShown ? '64px' : '72px',
          position: isAlertMessageShown ? 'absolute' : 'fixed'
        }}
      >
        <div className={ style.headerLiveMobileLogo }>
          {
            ((brand?.landingUrl) != null)
              ? <a href={ brand?.landingUrl } target="_blank" rel="noreferrer"><LogoIcon /></a>
              : <LogoIcon />
          }
        </div>

        <div className={ style.headerLiveMobileOptions }>
          <div className={ style.headerLiveMobileBtn }>
            <FabComponent variant="extended" size='small' onClick={ depositFundsBtn }>
              <AddFundsIcon color='var(--CFD-theme-System-Primary)'/>
              <span className={style.headerTitle}>{t('header.deposit_button_title')}</span>
            </FabComponent>
          </div>

          <div className={style['header-menu-wrapper']}>
            <div className={style['header-user-wrapper']} onClick={ settingsBtn }>
              <div className={style['user-info-wrapper']}>
                <span className={style['user-info-wallet']}>{`${symbolIcon} ${toFixed(selectedAccount?.balance ?? 0, 2)}`}</span>
                <span className={style['user-info-name']}>{`${firstName} ${lastName}` }</span>
              </div>
              {
                profileImage === null
                  ? firstName !== undefined && firstName.length > 0 && (
                      <div className={style.userIcon}>
                        {firstName[0]}
                      </div>
                  )
                  : <Avatar sx={{ width: 40, height: 40 }} alt={firstName} src={imageLink} />
              }
            </div>
          </div>
        </div>

      </div>
      { MenuSetting }
      { TransactionModal }
   </>
  )
}
