import React, { type FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks/redux';
import { verifyAccount } from '../../../redux/reducers/user';
import { type PayloadInterface } from '../../../interfaces';
import { useSnackbar } from '../../../components';
import { handleError } from '../../../helpers';
import { Wrapper, Header } from '../components';
import { AuthData } from '../../../auth/AuthWrapper';

export const VerifyAccount: FC = (): JSX.Element => {
  const { token } = useParams();
  const { handleOpen } = useSnackbar();
  const { loginByToken } = AuthData();
  const dispatch = useAppDispatch();

  const handleAccountVerificaion = async (token: string): Promise<void> => {
    const response = await dispatch(verifyAccount(token));
    const payload = response.payload as PayloadInterface;

    if (payload.isError) {
      handleError(payload, handleOpen);

      return;
    }

    localStorage.removeItem('registration-data');
    localStorage.removeItem('resend-btn-enabled');
    localStorage.removeItem('step');
    loginByToken(payload.data as string);
  }

  useEffect(() => {
    if (token !== undefined && token.length > 0) handleAccountVerificaion(token)
  }, [token]);

  return <Wrapper><Header/></Wrapper>
}
