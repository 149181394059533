import React, {
  type HTMLAttributes,
  type FC
} from 'react';

import { Step1SectionModalVerifications } from '../Steps/Step1/Section/Step1SectionModalVerifications';
import { Step2SectionModalVerifications } from '../Steps/Step2/Section/Step2SectionModalVerifications';
import { Step3SectionModalVerifications } from '../Steps/Step3/Section/Step3SectionModalVerifications';
import { SectionSuccessModal } from '../../../components';
import { t } from 'i18next';

interface ISectionModalVerification {
  step: number
  setFrontSideDocsPicked: (el: boolean) => void
  setBackSideDocsPicked: (el: boolean) => void
  setFrontSideCardPicked: (el: boolean) => void
  setBackSideCardPicked: (el: boolean) => void
  setSelfiePicked: (el: boolean) => void
}

type TSectionModalVerification = ISectionModalVerification & HTMLAttributes<HTMLElement>

export const SectionModalVerifications: FC<TSectionModalVerification> = ({
  step,
  setBackSideDocsPicked,
  setFrontSideDocsPicked,
  setFrontSideCardPicked,
  setBackSideCardPicked,
  setSelfiePicked
}) => {
  if (step === 1) {
    return (
        <Step1SectionModalVerifications setBackSideDocsPicked={ setBackSideDocsPicked } setFrontSideDocsPicked={ setFrontSideDocsPicked }/>
    )
  }

  if (step === 2) {
    return (
        <Step2SectionModalVerifications setFrontSideCardPicked={ setFrontSideCardPicked } setBackSideCardPicked={ setBackSideCardPicked } />
    )
  }

  if (step === 3) {
    return (
        <Step3SectionModalVerifications setSelfiePicked={ setSelfiePicked } />
    )
  }

  return (
    <SectionSuccessModal content={t('profile_page.settings.complete_profile.identity_verifications.verification_modal.section.success.title')}/>
  )
}
