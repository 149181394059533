import React, { type HTMLAttributes, type FC, useEffect, useState } from 'react';
import { ButtonComponent } from '../../../components';
import styles from './FooterModalVerifications.module.css';
import { t } from 'i18next';

type SetStateAction<T> = T | ((prevState: T) => T);

interface IFooterModalModalVerifications {
  step: number
  frontSideDocsPicked: boolean
  backSideDocsPicked: boolean
  frontSideCardPicked: boolean
  backSideCardPicked: boolean
  selfiePicked: boolean
  setStep: (el: SetStateAction<number>) => void
};

type TFooterModalVerifications = IFooterModalModalVerifications & HTMLAttributes<HTMLElement>;

export const FooterModalVerifications: FC<TFooterModalVerifications> = ({
  step,
  frontSideDocsPicked,
  backSideDocsPicked,
  frontSideCardPicked,
  backSideCardPicked,
  selfiePicked,
  setStep
}) => {
  const [nextBtnDisabled, setNextBtnDisabled] = useState<boolean>(false);
  const [skipBtnDisabled, setSkipBtnDisabled] = useState<boolean>(true);

  const handleNextBtn = (): void => {
    setStep((prevState: number) => (prevState += 1))
  }
  const handleSkipBtn = (): void => {
    setStep((prevState: number) => (prevState += 1))
  }

  useEffect(() => {
    const isNextBtnDisabled = (frontSideDocsPicked && backSideDocsPicked) ||
    (frontSideCardPicked && backSideCardPicked) || selfiePicked;

    setNextBtnDisabled(isNextBtnDisabled);
    setSkipBtnDisabled(!isNextBtnDisabled);
  }, [
    frontSideDocsPicked,
    backSideDocsPicked,
    frontSideCardPicked,
    backSideCardPicked,
    selfiePicked
  ])

  return (
    <footer className={ styles.dialogFooter }>
      { step !== 4
        ? <><div className={ styles.dialogFooterButton }>
          <ButtonComponent onClick={ handleNextBtn } disabled={ !nextBtnDisabled }>{t('labels.next')}</ButtonComponent>
      </div>
      <div className={ styles.dialogFooterButton }>
          <ButtonComponent onClick={ handleSkipBtn } variant='text' disabled={ !skipBtnDisabled } >{t('labels.skip_for_now')}</ButtonComponent>
      </div>
      <p className={ styles.dialogFooterSupportText }>{t('profile_page.settings.complete_profile.identity_verifications.verification_modal.footer.subtitle')}</p>
      </>
        : null }
    </footer>
  )
}
