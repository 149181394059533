import type { RootState } from '../../redux/store';

export const selectProfileSettingsIsIdCardVerified = (state: RootState): boolean => state.profileSettings.isIdCardVerified;
export const selectProfileSettingsIsCreditCardVerified = (state: RootState): boolean => state.profileSettings.isCreditCardVerified;
export const selectProfileSettingsIsSelfieVerified = (state: RootState): boolean => state.profileSettings.isSelfieVerified;
export const selectKycVerificationDate = (state: RootState): string => state.profileSettings.kycVerificationDate;
export const selectProfileSettingsVerificationStatus = (state: RootState): string => state.profileSettings.verificationStatus;
export const selectTwoFactorAuthDateAdded = (state: RootState): string => state.profileSettings.twoFactorAuthDateAdded;
export const selectProfileSettingsIsGoogleTwoFactorAuthenticationEnabled = (state: RootState): boolean => {
  return state.profileSettings.isGoogleTwoFactorAuthenticationEnabled;
}

export const selectProfileSettingsFirstName = (state: RootState): string => state.profileSettings.firstName;
export const selectProfileSettingsLastName = (state: RootState): string => state.profileSettings.lastName;
export const selectProfileSettingsCountry = (state: RootState): string => state.profileSettings.country;
export const selectProfileSettingsFullAddress = (state: RootState): string => state.profileSettings.fullAddress;
export const selectProfileSettingsPhoneNumber = (state: RootState): string => state.profileSettings.phoneNumber;
export const selectIsOccurErrorOnSettingsForm = (state: RootState): boolean => state.profileSettings.isOccurErrorOnSettingsForm;
